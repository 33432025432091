<template>
  <div id="buy-from-jci-preview">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ pageDescription }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner variant="primary" type="grow" label="Loading..."></b-spinner>
        </div>
        <v-container class="py-0">
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Seller Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Seller Name</h5>
              <p class="preview-content">{{ recordData.SellerName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Company Name</h5>
              <p class="preview-content">{{ recordData.CompanyName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Company Address</h5>
              <p class="preview-content">{{ recordData.CompanyAddress }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Sales Type</h5>
              <p class="preview-content">{{ recordData.BusinessType }}</p>
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Buyer Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h5 class="blue--text text--lighten">Buyer Name</h5>
              <p class="preview-content">{{ recordData.BuyerName }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h5 class="blue--text text--lighten">Buyer Mobile Number</h5>
              <p class="preview-content">{{ recordData.BuyerMobileNo }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="4" md="4">
              <h5 class="blue--text text--lighten">Buyer Email Id</h5>
              <p class="preview-content">{{ recordData.BuyerEmailId }}</p>
            </v-col>
          </v-row>
          <br />
          <br />
          <v-row wrap>
            <v-col cols="12" sm="12" lg="12" md="12">
              <h3 class="red--text text--lighten">Transaction Details</h3>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Transaction Date</h5>
              <p class="preview-content">{{ recordData.TransactionDateTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Transaction Type</h5>
              <p class="preview-content">{{ recordData.TransactionTypeTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Transaction Value</h5>
              <p class="preview-content">{{ recordData.TransactionValueTxt }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3">
              <h5 class="blue--text text--lighten">Transaction Compleated Date</h5>
              <p class="preview-content">{{ recordData.TransactionCompletedDate }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="recordData.InvoiceNumber != '' && recordData.InvoiceNumber != null">
              <h5 class="blue--text text--lighten">Invoice Number</h5>
              <p class="preview-content">{{ recordData.InvoiceNumber }}</p>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="recordData.GstInvoicePath != ''">
              <h5 class="blue--text text--lighten">GST Invoice</h5><v-btn
                :href="recordData.GstInvoicePath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-download </v-icon> GST Invoice
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="recordData.TransactionReceiptPath != ''">
              <h5 class="blue--text text--lighten">Transaction Receipt</h5><v-btn
                :href="recordData.TransactionReceiptPath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-download </v-icon> Transaction Receipt
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" lg="3" md="3" v-if="recordData.EwaybillPath != ''">
              <h5 class="blue--text text--lighten">Eway Bill</h5><v-btn
                :href="recordData.EwaybillPath"
                target="_blank"
                color="red"
                class="mx-2"
                dark
                small
              >
                <v-icon dark> mdi-file-download </v-icon> Eway Bill
              </v-btn>
            </v-col>
          </v-row>
          <br />
          <br />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closePrompt"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import AddNewProfession from "@/view/pages/profile/my-profile/AddNewProfession";

export default {
  mixins: [common],
  components: {
    AddNewProfession
  },
  props: {
    PreviewRecordPrompt: {
      type: Boolean,
      required: true
    },
    pageDescription: {
      type: String,
      required: true
    },
    recordData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      ProgressLoadingFlag: false,
      SubmitFlag: false,

      ZoneCode: "",
      LomCode: "",
      MemberId: "",
      PageInfo: {},
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: ""
    };
  },
  computed: {},
  mounted() {},
  watch: {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
    },
    backPage() {
      this.$router.go(-1);
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
  },
  beforeMount() {
    this.refreshPageData();
  }
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */
#buy-from-jci-preview {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .animate-button1 {
    color: white;

    animation: heartBeat; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 10s; /* don't forget to set a duration! */
  }
  .notes {
    color: red;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
  }
  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }
}
</style>